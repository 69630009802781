import * as React from 'react';


function NoResults(){
    return (    
        <section class="hero has-background-info-light">
            <div class="hero-body">
                <div class="container">
                    <p class="title">Select a City to View Tee Times</p>
                </div>
            </div>
        </section>
    );
}

export default NoResults;