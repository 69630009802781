import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs';


function Modal({courseName, courseInfo, isVisible, onModalExit}){

    console.log("Course name: " + courseName)
    console.log("Modal Data ")
    console.log(courseInfo)

    const reserveTeeTime = (link) => {
        window.open(link, "_blank")
    }

    const renderCard = (courseInfo) => {
        if (courseInfo && courseInfo.length){
            return (
                <div className="card">
                    <header className="card-header">
                        <h1 className="card-header-title is-size-4">
                            {courseName} - {dayjs(courseInfo[0].date, "MM-DD-YYYY").format("dddd, MMMM D")}
                        </h1>
                    </header>
                    <div class="content">
                        <table class="table is-striped">
                            <thead>
                                <tr>
                                    <th>
                                        <span class="icon-text">
                                            <span class="icon">
                                                <FontAwesomeIcon icon="fa-solid fa-clock" />
                                            </span>
                                            <span class="">Time</span>
                                        </span>
                                    </th>
                                    <th>
                                        <span class="icon-text">
                                            <span class="icon">
                                                <FontAwesomeIcon icon="fa-solid fa-users" />
                                            </span>
                                            <span class="">Golfers</span>
                                        </span>
                                    </th>
                                    <th>
                                        <span class="icon-text">
                                            <span class="icon">
                                                <FontAwesomeIcon icon="fa-solid fa-flag" />
                                            </span>
                                            <span class="">Holes</span>
                                        </span>
                                    </th>
                                    <th className="is-hidden-mobile"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    courseInfo.map((x, idx) => {
                                        return (
                                            <tr>
                                                <td>{x.time}</td>
                                                <td>{x.golfers}</td>
                                                <td>{x.holes}</td>
                                                <td className="is-hidden-mobile">
                                                    <button class="button is-fullwidth is-link" onClick={() => reserveTeeTime(x.link)}>Book Tee Time</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        {/* <div class="list has-visible-pointer-controls">
                            {
                                courseInfo.map((x, idx) => {
                                    return (
                                        <div class="list-item">
                                            <div class="list-item-content">
                                                <div class="list-item-title">{dayjs(x.date, "MM-DD-YYYY").format("dddd, MMMM D")} at {x.time}</div>
                                                <div class="list-item-description">Golfers: {x.golfers}</div>
                                                <div class="list-item-description">Holes: {x.holes}</div>
                                            </div>
            
                                            <div class="list-item-controls">
                                                <div class="buttons is-right">
                                                    <button class="button" onClick={() => reserveTeeTime(x.link)}>
                                                        <span class="icon is-small">
                                                            <i class="fas fa-edit"></i>
                                                        </span>
                                                        <span>Book Tee Time</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div> */}
                    </div>
                </div>
            )
        } else {
            return (
                <div className="card">
                <header className="card-header">
                    <h1 className="card-header-title">
                        {courseName}
                    </h1>
                </header>
                <div class="content">
                    No tee times available for this date and time :/ 
                </div>
            </div>
            )
        }
    }

    return (
        <div className={"modal " + (isVisible ? "is-active" : "")} onClick={() => onModalExit()} >
            <div className="modal-background"></div>
            <div className="modal-content">
                {renderCard(courseInfo)}
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={() => onModalExit()}></button>
        </div>
    );
}

export default Modal;

