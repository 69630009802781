import React, { useState } from 'react';


function Header({selectedCity, setSelectedCity, title, setTitle, cityList}){
    const [toggleMenu, setToggleMenu] = useState(false) 
    return (
        <div className="hero-head is-info" style={{"background-color": "#3e8ed0"}}>
            <nav className="navbar has-shadow is-info">
                <div className="navbar-brand">
                    <p className="is-size-3 is-capitalized is-family-roboto pl-6">
                        {title}
                    </p>
                </div>
                {/* // ${toggleMenu && 'is-active'} */}
                {/* <div class={`navbar-end navbar-item has-dropdown is-hoverable is-hidden-mobile is-hidden-tablet-only pr-5`} onClick={() => setToggleMenu(!toggleMenu)}>
                    <a class="navbar-link">
                        {selectedCity}
                    </a>
                    <div class={`navbar-dropdown`} >
                        {
                            cityList.map(city => {
                                if (city === selectedCity){
                                    return (
                                        <a class="navbar-item is-selected" onClick={() => {
                                            setSelectedCity(city)
                                            setTitle(`${city} Tee Times`)
                                        }}>
                                            {city}
                                        </a>
                                    )
                                } else {
                                    return (
                                        <a class="navbar-item" onClick={() => {
                                            setSelectedCity(city)
                                            setTitle(`${city} Tee Times`)
                                        }}>
                                            {city}
                                        </a> 
                                    )                                                                      
                                }
                            })
                        }
                    </div>
                </div>                 */}
            </nav>
        </div>   
    );
}

export default Header;