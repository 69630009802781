import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import teeTimesJSON from '../resources/time.json';
import DateFilter from './FilterComponents/DateFilter.jsx';
import TimeFilter from './FilterComponents/TimeFilter.jsx';
import HolesFilter from './FilterComponents/HolesFilter.jsx';
import GolfersFilter from './FilterComponents/GolfersFilter.jsx';
import CityFilter from './FilterComponents/CityFilter.jsx';
import FilterModal from './FilterModal'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function Form({
    onSelectDate, 
    onSelectStartTime, 
    onSelectEndTime, 
    onSelectGolfers, 
    onSelectHoles, 
    calendarDate, 
    startTime, 
    endTime, 
    golfers, 
    holes, 
    city, 
    setCity, 
    cityList,
    showFilters,
    setShowFilters
}){
    const today = dayjs()
    const teeTimes = JSON.parse(JSON.stringify(teeTimesJSON)).teeTimes;
    const teeTimeLen = teeTimes.length
    const [startTimeList, setStartTimeList] = useState(teeTimes.slice(0, teeTimeLen-1));
    const [endTimeList, setEndTimeList] = useState(teeTimes.slice(1));
    //const [startTime, setStartTime] = useState("05:00 AM");
    //const [endTime, setEndTime] = useState("07:00 PM");
    const [width, setWidth] = useState(window.innerWidth);
    const [showDateModal, setShowDateModal] = useState(false);
    const [showTimeModal, setShowTimeModal] = useState(false);
    const [showHolesModal, setShowHolesModal] = useState(false);
    const [showGolfersModal, setShowGolfersModal] = useState(false);
    const [showCityModal, setShowCityModal] = useState(false);

    // useEffect(() => {
    //     console.log(startTime)
    //     console.log(endTime)
    // }, [startTime, endTime])


    const filterTimeChangeStart = (time) => {
        const idx = teeTimes.indexOf(time)
        setEndTimeList(teeTimes.splice(idx+1, teeTimeLen))
    }

    const filterTimeChangeEnd = (time) => {
        const idx = teeTimes.indexOf(time)
        setStartTimeList(teeTimes.splice(0, idx))
    }

    useEffect(() => {
        const handleResize = () => {
            console.log(window.innerWidth)
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (width > 768) {
        return (
            <div className="field">
                {/* {
                    1215 1565 - inline
                    < 420 - inline
                } */}
                <div className="field is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
                    <div className="card">
                        <div className="card-content">
                            <div className="control is-expanded">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <StaticDatePicker defaultValue={calendarDate} onAccept={onSelectDate} maxDate={today.add(7, 'day')} minDate={today} orientation={'portrait'}/>
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="field is-hidden-widescreen-only is-hidden-fullhd">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                <span class="icon-text">
                                    <span class="icon">
                                        <FontAwesomeIcon icon="fa-solid fa-calendar" />
                                    </span>
                                    <span class="">Date</span>
                                </span>
                            </p>
                        </header>
                        <div className="card-content">
                            <div className="control is-expanded">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker defaultValue={calendarDate} onAccept={onSelectDate} maxDate={today.add(7, 'day')} minDate={today} slotProps={{ textField: { fullWidth: true } }}/>
                                </LocalizationProvider>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="field">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                <span class="icon-text">
                                    <span class="icon">
                                        <FontAwesomeIcon icon="fa-solid fa-users" />
                                    </span>
                                    <span class="">Golfers</span>
                                </span>
                            </p>
                        </header>
                        <div className="card-content">
                            <div className="control is-expanded">
                                <div className="select is-fullwidth">
                                    <select defaultValue={golfers} onChange={(e) => { onSelectGolfers(e.target.value) }}> 
                                        <option>Any</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="field">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                <span class="icon-text">
                                    <span class="icon">
                                        <FontAwesomeIcon icon="fa-solid fa-flag" />
                                    </span>
                                    <span class="">Holes</span>
                                </span>
                            </p>
                        </header>
                        <div className="card-content">
                            <div className="control is-expanded">
                                <div className="select is-fullwidth">
                                    <select defaultValue={holes} onChange={(e) => { onSelectHoles(e.target.value)}}>
                                        <option>Any</option>
                                        <option>18</option>
                                        <option>9</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="field">
                    <div className="card">
                        <header className="card-header">
                            <p className="card-header-title">
                                <span class="icon-text">
                                    <span class="icon">
                                        <FontAwesomeIcon icon="fa-solid fa-clock" />
                                    </span>
                                    <span class="">Time</span>
                                </span>
                            </p>
                            <a style={{padding: "0.75rem 1rem", "align-items": "center", "font-weight": "600"}} onClick={(e) => {
                                setStartTimeList(teeTimes.slice(0, teeTimeLen-1))
                                onSelectStartTime("05:00 AM")
                                //setStartTime("05:00 AM")
    
                                setEndTimeList(teeTimes.slice(1))
                                onSelectEndTime("07:00 PM")
                                //setEndTime("07:00 PM")
                            }}>
                                Reset
                            </a>
                        </header>
                        <div className="card-content">
                            <div className="control is-expanded">
                                <div className="field">
                                    <div className="columns is-8-widescreen is-2-mobile">
                                        <div className="column">
                                            <label className="label">Start</label>
                                            <div className="select is-fullwidth">
                                                <select onChange={(e) => {
                                                    onSelectStartTime(e.target.value)
                                                    //setStartTime(e.target.value)
                                                    filterTimeChangeStart(e.target.value)
                                                }}>
                                                    {
                                                        startTimeList.map((time, idx) => {
                                                            if (time === startTime){
                                                                return (<option key={idx} selected>{time}</option>)
                                                            } else {
                                                                return (<option key={idx}>{time}</option>)
                                                            }
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <label className="label">End</label>
                                            <div className="select is-fullwidth">
                                                <select onChange={(e) => {
                                                    onSelectEndTime(e.target.value)
                                                    //setEndTime(e.target.value)
                                                    filterTimeChangeEnd(e.target.value)
                                                }}>
                                                    {
                                                        endTimeList.map((time, idx) => {
                                                            if (time === endTime){
                                                                return (<option key={idx} selected>{time}</option>)
                                                            } else {
                                                                return (<option key={idx}>{time}</option>)
                                                            }
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="control is-expanded">
                <FilterModal 
                    filter={<DateFilter 
                        calendarDate={calendarDate} 
                        onSelectDate={onSelectDate} 
                        onModalExit={() => setShowDateModal(false)}
                    />}
                    onModalExit={() => setShowDateModal(false)}
                    isVisible={showDateModal} 
                />
                <FilterModal 
                    filter={<TimeFilter 
                        startTime={startTime}
                        endTime={endTime}
                        startTimeList={startTimeList}
                        endTimeList={endTimeList}
                        onSelectStartTime={onSelectStartTime}
                        onSelectEndTime={onSelectEndTime}
                        // setStartTime={setStartTime}
                        // setEndTime={setEndTime}
                        filterTimeChangeStart={filterTimeChangeStart} 
                        filterTimeChangeEnd={filterTimeChangeEnd}
                        onModalExit={() => setShowTimeModal(false)}
                    />}
                    onModalExit={() => setShowTimeModal(false)}
                    isVisible={showTimeModal} 
                />
                <FilterModal 
                    filter={<HolesFilter 
                        holes={holes}
                        onSelectHoles={onSelectHoles}
                        onModalExit={() => setShowHolesModal(false)}
                    />}
                    onModalExit={() => setShowHolesModal(false)}
                    isVisible={showHolesModal} 
                />
                <FilterModal 
                    filter={<GolfersFilter 
                        golfers={golfers}
                        onSelectGolfers={onSelectGolfers}
                        onModalExit={() => setShowGolfersModal(false)}
                    />}
                    onModalExit={() => setShowGolfersModal(false)}
                    isVisible={showGolfersModal} 
                />
                {/* <FilterModal 
                    filter={<CityFilter 
                        city={city}
                        setCity={setCity}
                        cityList={cityList}
                        onModalExit={() => setShowCityModal(false)}
                    />}
                    onModalExit={() => setShowCityModal(false)}
                    isVisible={showCityModal} 
                /> */}
                <div class="content has-text-centered mb-3 has-text-weight-semibold">
                    Showing Tee Times for <span className="has-text-weight-bold has-text-link">{calendarDate.format("dddd, MMMM D")}</span> between <span className="has-text-weight-bold has-text-link">{startTime} - {endTime}</span>
                </div>
                <button className="button is-fullwidth is-outlined has-text-weight-bold" onClick={() => setShowFilters(!showFilters)}>
                    <span class="icon-text">
                        <span class="icon">
                            <FontAwesomeIcon icon="fa-solid fa-filter" />
                        </span>
                        <span class="">Filters</span>
                    </span>
                </button>
                <div hidden={!showFilters}>
                    <div class="columns is-multiline is-mobile mt-3 is-0" >
                        {/* <div class="column pr-2">
                            <button class="button is-info is-outlined is-fullwidth has-text-weight-bold" onClick={() => setShowCityModal(true)}>City</button>
                        </div> */}
                        <div class="column pl-2 pr-2">
                            <button class="button is-info is-outlined is-fullwidth has-text-weight-bold" onClick={() => setShowDateModal(true)}>Date</button>
                        </div>
                        <div class="column pl-2 pr-2">
                            <button class="button is-info is-outlined is-fullwidth has-text-weight-bold" onClick={() => setShowTimeModal(true)}>Time</button>
                        </div>
                        <div class="column pl-2 pr-2">
                            <button class="button is-info  is-outlined is-fullwidth has-text-weight-bold" onClick={() => setShowGolfersModal(true)}>Golfers</button>
                        </div>
                        <div class="column pl-2">
                            <button class="button is-info is-outlined is-fullwidth has-text-weight-bold" onClick={() => setShowHolesModal(true)}>Holes</button>
                        </div>
                    </div>
                </div>
                {/* <button hidden={!showFilters} class="button is-info is-outlined is-fullwidth has-text-weight-bold mt-3">Reset Filters</button> */}
            </div>        
        )
    }


}

export default Form;

                    {/* <header className="card-header">
                        <p className="card-header-title">
                            <span class="icon-text">
                                <span class="icon">
                                    <i class="fas fa-solid fa-people-group"></i>
                                </span>
                                <span class="">Date</span>
                            </span>
                        </p>
                    </header> */}

        {/* <div class="field">
            <label class="label">Select a Golf Course</label>
            <div class="control is-expanded">
                <div class="select is-fullwidth">
                    <select>
                        <option>Warm Springs</option>
                        <option>Quail Hollow</option>
                        <option>Falcon Crest</option>
                        <option>Shadow Valley</option>
                        <option>Ridge Crest</option>
                        <option>Bandburry</option>
                    </select>
                </div>
            </div>
        </div> */}

                    {/* <div className="field">
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title">
                            <span class="icon-text">
                                <span class="icon">
                                    <i class="fas fa-solid fa-people-group"></i>
                                </span>
                                <span class="">Date</span>
                            </span>
                        </p>
                    </header>
                    <div className="card-content">
                        <div className="control is-expanded">
                            <div className="is-fullwidth">
                                <Calendar  onSelectDate={onSelectDate}/>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div> */}

                        {/* <div className="field">
                <label className="label">Holes</label>
                <div className="control is-expanded">
                    <div className="select is-fullwidth">
                        <select defaultValue={18} onChange={(e) => { filterChange(e.target.value, onSelectHoles) }}>
                            <option>18</option>
                            <option>9</option>
                        </select>
                    </div>
                </div>
            </div> */}


            {/* <div className="field"> //filterChange(e.target.value, onSelectGolfers)
                <label className="label">Golfers</label>
                <div className="control is-expanded">
                    <div className="select is-fullwidth">
                        <select defaultValue={numGolfers} onChange={(e) => filterChange(e.target.value, onSele)}>
                            <option>4</option>
                            <option>3</option>
                            <option>2</option>
                            <option>1</option>
                        </select>
                    </div>
                </div>
            </div> */}
            {/* <div className="field">
                <label className="label">
                    <span class="icon-text">
                        <span class="icon">
                            <i class="fas fa-solid fa-people-group"></i>
                        </span>
                        <span class="">Golfers</span>
                    </span>
                </label>
                <div class="is-divider mt-2 mb-3"></div>
                <div className="control is-expanded">
                    <div className="select is-fullwidth">
                        <select defaultValue={18} onChange={(e) => { filterChange(e.target.value, onSelectHoles) }}>
                            <option>Any</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </select>
                    </div>
                </div>
            </div> */}

                        {/* <div className="field">
                <div className="control is-expanded mt-1">
                    <button className={"button is-fullwidth is-info " + (isLoading ? "is-loading" : "")} onClick={() => onSubmit(date, time, numHoles, numGolfers)}>Submit</button>
                </div>
            </div> */}

                        {/* <div className="field">
                <div className="card">
                    <header className="card-header">
                        <p className="card-header-title">
                            <span class="icon-text">
                                <span class="icon">
                                    <i class="fas fa-solid fa-people-group"></i>
                                </span>
                                <span class="">Golfers</span>
                            </span>
                        </p>
                    </header>
                    <div className="card-content">
                        <div className="control is-expanded">
                            <div className="field is-grouped">
                                <button class="button is-fullwidth is-info is-outlined is-active" onChange={(e) => { filterChange(e.target.value, onSelectGolfers) }}>Any</button>
                                <button class="button is-fullwidth is-info is-outlined ml-3" onChange={(e) => { filterChange(e.target.value, onSelectGolfers) }}>1</button>
                                <button class="button is-fullwidth is-info is-outlined ml-3 mr-3" onChange={(e) => { filterChange(e.target.value, onSelectGolfers) }}>2</button>
                                <button class="button is-fullwidth is-info is-outlined mr-3" onChange={(e) => { filterChange(e.target.value, onSelectGolfers) }}>3</button>
                                <button class="button is-fullwidth is-info is-outlined" onChange={(e) => { filterChange(e.target.value, onSelectGolfers) }}>4</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}