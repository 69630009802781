import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TimeFilter ({
    startTime,
    endTime, 
    startTimeList, 
    endTimeList, 
    onSelectStartTime, 
    onSelectEndTime, 
    filterTimeChangeStart, 
    filterTimeChangeEnd,
    onModalExit
}) {
    return (
        <div className="field">
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        <span class="icon-text">
                            <span class="icon">
                                <FontAwesomeIcon icon="fa-solid fa-clock" />
                            </span>
                            <span class="">Time</span>
                        </span>
                    </p>
                    <a style={{padding: "0.75rem 1rem", "align-items": "center", "font-weight": "600"}} onClick={(e) => {
                        onModalExit()
                    }}>
                        Close
                    </a>
                </header>
                <div className="card-content">
                    <div className="control is-expanded">
                        <div className="field">
                            <div className="columns is-8-widescreen is-2-mobile">
                                <div className="column">
                                    <label className="label">Start</label>
                                    <div className="select is-fullwidth">
                                        <select onChange={(e) => {
                                            onSelectStartTime(e.target.value)
                                            //setStartTime(e.target.value)
                                            filterTimeChangeStart(e.target.value)
                                        }}>
                                            {
                                                startTimeList.map((time, idx) => {
                                                    if (time === startTime){
                                                        return (<option key={idx} selected>{time}</option>)
                                                    } else {
                                                        return (<option key={idx}>{time}</option>)
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="column">
                                    <label className="label">End</label>
                                    <div className="select is-fullwidth">
                                        <select onChange={(e) => {
                                            onSelectEndTime(e.target.value)
                                            //setEndTime(e.target.value)
                                            filterTimeChangeEnd(e.target.value)
                                        }}>
                                            {
                                                endTimeList.map((time, idx) => {
                                                    if (time === endTime){
                                                        return (<option key={idx} selected>{time}</option>)
                                                    } else {
                                                        return (<option key={idx}>{time}</option>)
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeFilter;