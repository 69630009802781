import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function GolfersFilter({golfers, onSelectGolfers, onModalExit}){
    return (
        <div className="field">
            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        <span class="icon-text">
                            <span class="icon">
                                <FontAwesomeIcon icon="fa-solid fa-users" />
                            </span>
                            <span class="">Golfers</span>
                        </span>
                    </p>
                    <a style={{padding: "0.75rem 1rem", "align-items": "center", "font-weight": "600"}} onClick={(e) => {
                        onModalExit()
                    }}>
                        Close
                    </a>
                </header>
                <div className="card-content">
                    <div className="control is-expanded">
                        <div className="select is-fullwidth">
                            <select defaultValue={golfers} onChange={(e) => { onSelectGolfers(e.target.value) }}> 
                                <option>Any</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GolfersFilter;