import React, { useState, useEffect } from 'react';
import Form from "./Form"
import Results from "./Results"
import NoResults from "./NoResults"
import Modal from "./Modal"
import dayjs from 'dayjs';
import useWindowDimensions from '../util/windowDimensions'
import 'bulma-extensions/bulma-pageloader/dist/css/bulma-pageloader.min.css';
import 'bulma-extensions/bulma-divider/dist/css/bulma-divider.min.css';


function Search({ city, setCity, cityList, courseInfo, teeTimeData, filteredTeeTimeData, setFilteredTeeTimeData, onDateChange, isLoading, date }){
    const today = dayjs()
    // const teeTimeDataJSON = JSON.parse(JSON.stringify(jsonTeeTimeData));
    // const [teeTimeData, setTeeTimeData] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [courseName, setCourseName] = useState("");
    const [startTime, setStartTime] = useState("05:00 AM");
    const [endTime, setEndTime] = useState("07:00 PM");
    const [golfers, setGolfers] = useState('Any');
    const [holes, setHoles] = useState('Any');
    const [showFilters, setShowFilters] = useState(false);
    const { height, width } = useWindowDimensions();
    

    useEffect(() => {
        onFilterChange()
    }, [startTime, endTime, golfers, holes, teeTimeData])

    const onFilterChange = () => {
        var filteredTeeTimes = {}
        for (const [courseName, teeTimes] of Object.entries(teeTimeData)) {
            filteredTeeTimes[courseName] = teeTimes.filter(teeTimeFilter)
        }
        setFilteredTeeTimeData(filteredTeeTimes)
    }

    const teeTimeFilter = (teeTime) => {
        const timeNow = dayjs(today)
        const startTimeObj = dayjs(startTime, "hh:mm A");
        const teeTimeObj = dayjs(teeTime["time"], "hh:mm A");
        const endTimeObj = dayjs(endTime, "hh:mm A");
        return (holes === 'Any' || teeTime["holes"] >= holes) 
                && (golfers === 'Any' || golfers >= teeTime['golfers']) 
                && startTimeObj.isBefore(teeTimeObj) 
                && teeTimeObj.isBefore(endTimeObj) 
                && ((date.isSame(today, 'day') && !teeTimeObj.isBefore(timeNow)) || !date.isSame(today, 'day'))
    }

    const onCardClick = (courseName) => {
        // Set the tee time data
        // make the modal visible
        setCourseName(courseName);
        setIsModalVisible(true);
    }

    const onModalExit = () => {
        console.log("Closing Modal")
        setIsModalVisible(false);
    }

    const formStyle = () => {
        if (width > 768){
            return {
                height: `${height-52-32-96}px`, 
                'overflow-y': "auto"
            }
        } else {
            return {}
        }
    }

    const stickyHeader = () => {
        return {
            "position": "sticky",
            "top": "0",
            "z-index": "999",
            "background-color": "#eff5fb"
        }
    }

    const formStyleResults = () => {
        if (width > 768){
            return {
                height: `${height-52-32-96}px`, 
                'overflow-y': "auto",
                "overflowX": "hidden"
            }
        } else {
            if (!showFilters){
                return {
                    height: `${height-52-32-96-100}px`, 
                    'overflow-y': "auto",
                    "overflowX": "hidden"
                }
            }
            return {
                height: `${height-52-164-32-96}px`, 
                'overflow-y': "auto",
                "overflowX": "hidden"
            }
        }
    }

    // 768

    return (
        <section className="section pt-4 pb-4 has-background-info-light">
            <div className="">
                <Modal 
                    courseName={courseName} 
                    courseInfo={filteredTeeTimeData[courseName]} 
                    isVisible={isModalVisible} 
                    onModalExit={onModalExit}
                />
                <div className="columns is-centered">

                    <div className="column is-4" style={formStyle()}>
                        <Form 
                            onSelectDate={onDateChange} 
                            onSelectStartTime={setStartTime} 
                            onSelectEndTime={setEndTime} 
                            onSelectGolfers={setGolfers} 
                            onSelectHoles = {setHoles}
                            calendarDate = {date}
                            startTime = {startTime}
                            endTime = {endTime}
                            holes = {holes}
                            golfers = {golfers}
                            city = {city}
                            setCity = {setCity}
                            cityList = {cityList}
                            showFilters = {showFilters}
                            setShowFilters = {setShowFilters}
                        />
                    </div>
                    {/* style={{  "height": "100vh", "overflow": "auto" }}
                    style={{  "height": "100vh", "overflow": "auto" }} */}
                    <div class="is-divider-vertical is-hidden-tablet-only is-hidden-mobile"></div>
                    <div className="column is-8 is-outlined" >
                        <div class={"pageloader is-info " + (isLoading ? "is-active" : "")}><span class="title">Loading Tee Times</span></div>
                        {!cityList.includes(city) ? <NoResults/> : 
                            <div style={formStyleResults()}>
                                <div class="content mb-3 has-text-weight-semibold is-hidden-mobile" style={stickyHeader()}>
                                    Showing Tee Times for <span className="has-text-weight-bold has-text-link">{date.format("dddd, MMMM D")}</span> between <span className="has-text-weight-bold has-text-link">{startTime} - {endTime}</span>
                                </div>
                                <Results  teeTimeData={filteredTeeTimeData} courseInfo={courseInfo} onCardClick={onCardClick} />
                            </div>
                        }                       
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Search;