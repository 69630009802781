import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../util/windowDimensions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ResultCard({courseName, number, address, url, onCardClick, isButtonActive, teeTimeData}){
    const [showTeeTime, setShowTeeTime] = useState(false);
    const [showCourseInfo, setShowCourseInfo] = useState(false);
    const { height, width } = useWindowDimensions();


    const formStyleResults = () => {
        var tableHeight;
        if (teeTimeData.length > 4){
            tableHeight = 41.5 * 5;
        } else if (teeTimeData.length == 0){
            tableHeight = 0;
        } else {
            tableHeight = (teeTimeData.length + 1) * 41.5
        }
        return {
            height: `${tableHeight}px`, 
            'overflow-y': "auto",
            "overflowX": "hidden",
        }
    }

    const styleHeader = () => {
        return {
            "position": "sticky",
            "top": 0,
            "background-color": "#fff"
        }
    }

    return (
        <div className="card">
            <header className="card-header">
                <p className="card-header-title" onClick={(e) => {
                    setShowCourseInfo(!showCourseInfo)
                    setShowTeeTime(false)
                }}>
                    {courseName}
                </p>
                <a style={{padding: "0.75rem 1rem", "align-items": "center", "font-weight": "600"}} onClick={(e) => {
                    setShowTeeTime(!showTeeTime)
                    setShowCourseInfo(false)
                }}>
                    {teeTimeData.length > 0 ? showTeeTime ? "Close" : "Tee Times" : "" }
                </a>
                {/* <a style={{padding: "0.75rem 1rem", "align-items": "center", "font-weight": "600"}} onClick={(e) => {
                    setShowTeeTime(!showTeeTime)
                }}>
                    {showTeeTime ? "Close" : "Course Info"}
                </a> */}
            </header>
            {showCourseInfo ? 
                <div className="card-content">
                    {/* <header className="card-header"> */}
                        <div class="columns is-centered">
                            <div class="column has-text-centered">
                                <a href={'https://maps.google.com/?q='+address}>
                                    <a href={'https://maps.apple.com/maps?q='+address}>
                                        <span class="icon-text">
                                            <span class="icon">
                                            <FontAwesomeIcon icon="fa-solid fa-map" />
                                            </span>
                                            <span class="">Directions</span>
                                        </span>
                                    </a>
                                </a>
                            </div>
                            <div class="column has-text-centered">
                                <a className="" href={"tel:"+number}>
                                    <span class="icon-text">
                                        <span class="icon">
                                            <i class="fas fa-solid fa-phone"></i>
                                        </span>
                                        <span class="">Call</span>
                                    </span>
                                </a>
                            </div>
                            <div class="column has-text-centered">
                                <a className="" href={url}>
                                    <span class="icon-text">
                                        <span class="icon">
                                            <FontAwesomeIcon icon="fa-solid fa-globe" />
                                        </span>
                                        <span class="">Book Online</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    {/* </header> */}
                </div>
            : <div className="card"></div>}
            {showTeeTime ? 
                <div className="card">
                    <div class="content" style={formStyleResults()}>
                        <table class="table is-striped">
                            <thead style={styleHeader()}>
                                <tr style={styleHeader()}>
                                    <th style={styleHeader()}>
                                        <span class="icon-text">
                                            <span class="icon">
                                                <FontAwesomeIcon icon="fa-solid fa-clock" />
                                            </span>
                                            <span class="">Time</span>
                                        </span>
                                    </th>
                                    <th style={styleHeader()}>
                                        <span class="icon-text">
                                            <span class="icon">
                                                <FontAwesomeIcon icon="fa-solid fa-users" />
                                            </span>
                                            <span class="">Golfers</span>
                                        </span>
                                    </th>
                                    <th style={styleHeader()}>
                                        <span class="icon-text">
                                            <span class="icon">
                                                <FontAwesomeIcon icon="fa-solid fa-flag" />
                                            </span>
                                            <span class="">Holes</span>
                                        </span>
                                    </th>
                                    {/* <th className="is-hidden-mobile" style={styleHeader()}>
                                        <span class="icon-text">
                                            <span class="icon">
                                                <FontAwesomeIcon icon="fa-solid fa-link" />
                                            </span>
                                            <span class="">Link</span>
                                        </span>
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    teeTimeData.map((x, idx) => {
                                        return (
                                            <tr>
                                                <td>{x.time}</td>
                                                <td>{x.golfers}</td>
                                                <td>{x.holes}</td>
                                                {/* <td className="is-hidden-mobile">
                                                    <button class="button is-fullwidth is-link" onClick={() => reserveTeeTime(x.link)}>Book Tee Time</button>
                                                </td> */}
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
            : <div className="card"></div>}
        </div>
    );
}

export default ResultCard;

            {/* <div className="card-content">
                <div class="list">
                    <div class="list-item">
                        <div class="list-item-content">
                            <div class="list-item-description">
                                <a href={'https://maps.google.com/?q='+address}>
                                    <a href={'https://maps.apple.com/maps?q='+address}>
                                        <span class="icon-text">
                                            <span class="icon">
                                            <FontAwesomeIcon icon="fa-solid fa-map" />
                                            </span>
                                            <span class="">{address}</span>
                                        </span>
                                    </a>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="list-item-content">
                            <div class="list-item-description">
                                <a className="" href={"tel:"+number}>
                                    <span class="icon-text">
                                        <span class="icon">
                                            <i class="fas fa-solid fa-phone"></i>
                                        </span>
                                        <span class="">{number}</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="list-item-content">
                            <div class="list-item-description">
                                <a className="" href={url}>
                                    <span class="icon-text">
                                        <span class="icon">
                                            <FontAwesomeIcon icon="fa-solid fa-globe" />
                                        </span>
                                        <span class="">Book Tee Time</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-5"> 
                    {
                        isButtonActive 
                        ? (<button onClick={() => onCardClick(courseName)} className="button card-footer-item is-info is-fullwidth has-text-weight-bold">View Tee Times</button>) 
                        : (<button onClick={() => onCardClick(courseName)} className="button card-footer-item is-info is-fullwidth has-text-weight-bold" disabled>View Tee Times</button>)
                    }
                    
                </div> */}
            {/* </div> */}
            {/* <footer className="card-footer">
                <button onClick={() => onCardClick(courseName)} className="button card-footer-item is-info is-fullwidth has-text-weight-bold">View Tee Times</button>
            </footer> */}
                        {/* <div class="list has-visible-pointer-controls">
                            {
                                courseInfo.map((x, idx) => {
                                    return (
                                        <div class="list-item">
                                            <div class="list-item-content">
                                                <div class="list-item-title">{dayjs(x.date, "MM-DD-YYYY").format("dddd, MMMM D")} at {x.time}</div>
                                                <div class="list-item-description">Golfers: {x.golfers}</div>
                                                <div class="list-item-description">Holes: {x.holes}</div>
                                            </div>
            
                                            <div class="list-item-controls">
                                                <div class="buttons is-right">
                                                    <button class="button" onClick={() => reserveTeeTime(x.link)}>
                                                        <span class="icon is-small">
                                                            <i class="fas fa-edit"></i>
                                                        </span>
                                                        <span>Book Tee Time</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div> */}
                    {/* <header className="card-header">
                        <h1 className="card-header-title is-size-4">
                            {courseName} - {dayjs(teeTimeData[0].date, "MM-DD-YYYY").format("dddd, MMMM D")}
                        </h1>
                    </header> */}