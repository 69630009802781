import React, { useState, useEffect } from 'react';


function FilterModal({filter, isVisible, onModalExit}){
    return (
        <div className={"modal " + (isVisible ? "is-active" : "")}>
            <div className="modal-background"/>
            <div className="modal-content">
                {filter}
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={() => onModalExit()}></button>
        </div>
    )
}

export default FilterModal;