import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

function DateFilter ({calendarDate, onSelectDate, onModalExit}) {
    const today = dayjs()
    return (
        <div className="card">
            <div className="card-content">
                <div className="control is-expanded">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDatePicker defaultValue={calendarDate} onClose={() => onModalExit()} onAccept={(val) => {
                            onSelectDate(val)
                            onModalExit()
                        }} maxDate={today.add(7, 'day')} minDate={today} orientation={'portrait'}/>
                    </LocalizationProvider>
                </div>
            </div>
        </div>
    )
}

export default DateFilter;