import React, { useState, useEffect } from 'react';
import Header from '../components/Header'
import Footer from '../components/Footer'
import Search from '../components/Search'
import boise from '../resources/boise.json';
import dayjs from 'dayjs';


function Home(){
    const today = dayjs()
    const url = "https://osvzoa9888.execute-api.us-west-2.amazonaws.com/api"; //"http://127.0.0.1:8000" 
    const [courseInfo, setCourseInfo] = useState({});
    const [date, setDate] = useState(today);
    const [isLoading, setIsLoading] = useState(false);
    const cityList = ["Boise, ID", "Salt Lake City, UT", "McCall, ID"]
    const [selectedCity, setSelectedCity] = useState("Boise, ID")
    const [title, setTitle] = useState("Boise Tee Times")
    const [teeTimeData, setTeeTimeData] = useState({});
    const [filteredTeeTimeData, setFilteredTeeTimeData] = useState({});


    useEffect(() => {
        var headers = {}
        var requestOptions = {
            'method': 'GET',
            'redirect': 'follow',
            'headers': headers
        };
        const cityUrl = formatUrl(today)
        if (cityUrl) {
            setIsLoading(true)
            fetch(cityUrl, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("Result: " + result)
                setCourseInfo(JSON.parse(JSON.stringify(boise)))
                setTeeTimeData(result["data"])
                setFilteredTeeTimeData(result["data"])
                setIsLoading(false)
            })
            .catch(error => console.log('error', error));
        }
    }, [ selectedCity ]);

    const cityPath = () =>{
        switch(selectedCity) {
            case "Boise, ID":
                return "boise"
            case "Salt Lake City, UT":
                return "slc";
            case "McCall, ID":
                return "mccall"
            default:
                return ""
        }
    }

    const setCityCourseInfo = () => {
        switch(selectedCity) {
            case "Boise, ID":
                return "boise"
            case "Salt Lake City, UT":
                return "slc";
            case "McCall, ID":
                return "mccall"
            default:
                return ""
        }
    }

    const formatUrl = (date) => {
        const path = cityPath()
        if (!path) {
            return ""
        }
        return `${url}/${path}?date=${date.format("MM/DD/YYYY")}`
    }

    const onDateChange = (dateObj) => {
        setDate(dateObj)
        var headers = {}
        var requestOptions = {
            'method': 'GET',
            'redirect': 'follow',
            'headers': headers
        };
        const cityUrl = formatUrl(dateObj)
        if (cityUrl){
            setIsLoading(true)
            fetch(cityUrl, requestOptions)
            .then(response => response.json())
            .then(result => {
                setIsLoading(false)
                setTeeTimeData(result["data"])
            })
            .catch(error => console.log('error', error));
        }
    }

    return (    
        <div className="hero has-background-info-light is-fullheight">
            <Header
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                title={title}
                setTitle={setTitle}
                cityList={cityList}
            />
            <Search
                city={selectedCity}
                setCity={setSelectedCity}
                cityList={cityList}
                courseInfo={courseInfo}
                teeTimeData={teeTimeData}
                filteredTeeTimeData={filteredTeeTimeData}
                setFilteredTeeTimeData={setFilteredTeeTimeData}
                onDateChange={onDateChange}
                isLoading={isLoading}
                date={date}
            />
            <Footer/>
        </div>
    );
}

export default Home;