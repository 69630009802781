import React, { useState, useEffect } from 'react';
import ResultCard from "./ResultCard"


function Results({teeTimeData, courseInfo, onCardClick}){

    const generateCards = (teeTimeData, courseInfo, onCardClick) => {
        return Object.keys(courseInfo).map((name) => {
            var isButtonActive = true
            if (!(name in teeTimeData) || teeTimeData[name].length === 0) {
                isButtonActive = false
            }
            return (
                <div className="column is-12" key={name}>
                    <ResultCard 
                        courseName={name} 
                        number={courseInfo[name]["phoneNumber"]} 
                        address={courseInfo[name]["address"]} 
                        url={courseInfo[name]["url"]} 
                        onCardClick={onCardClick} 
                        isButtonActive={isButtonActive} 
                        teeTimeData={teeTimeData[name]}
                    />
                </div>
            )
        })
    }

    return (
        <div className="columns is-multiline"> 
            {generateCards(teeTimeData, courseInfo, onCardClick)}
        </div>
    );
}

export default Results;